












































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
// import snakeCaseKeys from 'snakecase-keys'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import isEmpty from 'lodash/isEmpty'

import TablePagination from '@/partials/tables/Pagination.vue'
import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PermissionsMixin from '@/mixins/permissionsMixin'
import ArrowDown16 from '@carbon/icons-vue/es/arrow--down/16'
import ArrowUp16 from '@carbon/icons-vue/es/arrow--up/16'
import NotificationMixin from '@/mixins/notificationMixin'

@Component({
  components: {
    ArrowDown16,
    ArrowUp16,
    GenericModalDelete,
    TrashCan16,
    Edit16,
    NotificationMixin,
    TablePagination
  }
})
export default class MedicalFormQuestionsTable extends Mixins(FlashMessageMixin, PermissionsMixin) {
  @Prop({ required: true, type: String }) formId!: string
  questions = [] as any
  totalQuestions = 0
  deleteModalIsOpened = false
  selectedQuestion = {
    id: '',
    title: ''
  }

  form: Record<string, any> = {
    title: '',
    body: '',
    customizable: ''
  }

  asdf = 'Para acessar outros modelos de Questionários ou para conduzir Grupos de Pesquisa, entre em contato com a <a href="https://indicativa.com.br" target="_blank" class="underline text-indigo-500">[i] Indicativa</a>.'

  created () {
    this.fetch()
  }

  fetch () {
    axios.get(`/medical/form/${this.formId}/question`)
      .then(response => {
        Object.assign(this.form, camelCaseKeys(response.data.data, { deep: true }))
        this.questions = camelCaseKeys(response.data.data, { deep: true })
        this.totalQuestions = this.questions.length
      })
  }

  moveChoice (index: number, direction: string) {
    let targetIndex: number

    if (direction === 'up') {
      targetIndex = index - 1
    } else {
      targetIndex = index + 1
    }

    const swappingChoice = this.questions[targetIndex]

    this.questions.splice(targetIndex, 1)
    this.questions.splice(index, 0, swappingChoice)

    this.submitQuestionsOrder()
  }

  submitQuestionsOrder () {
    const orderedQuestions = this.questions.map((question: any, index: number) => {
      return { id: question.id, order: index }
    })

    const submitForm = {
      questions: orderedQuestions
    }

    axios.put(`/medical/form/${this.formId}/question/reorder`, submitForm)
      .then((response) => {
        this.questions = camelCaseKeys(response.data.data).map((question: any, index: number) => {
          question.order = index
          return question
        })
      })
  }

  postDeleteSubmit () {
    this.deleteModalIsOpened = false

    const flashMessage: flashMessage = {
      message: 'A Questão foi removida com sucesso.',
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetch()
  }

  onPageChange () {
    this.fetch()
  }

  get deleteUrl () {
    return isEmpty(this.selectedQuestion) ? '' : `/medical/form/${this.formId}/question/${this.selectedQuestion.id}`
  }
}
