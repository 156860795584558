







import { Component, Vue } from 'vue-property-decorator'

import CenteredThin from '@/layouts/Panels/CenteredThin30-50.vue'
import MedicalFormAnswerForm from '@/partials/forms/Medical/Form/Answer/form.vue'

@Component({
  components: {
    MedicalFormAnswerForm,
    CenteredThin
  }
})
export default class MedicalFormAnswer extends Vue {

}
