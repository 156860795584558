








import { Component, Vue } from 'vue-property-decorator'

import MedicalFormForm from '@/partials/forms/Medical/Form/form.vue'
import MedicalFormQuestionsTable from '@/partials/tables/Medical/Forms/Questions.vue'

@Component({
  components: {
    MedicalFormQuestionsTable,
    MedicalFormForm
  }
})
export default class MedicalFormsEdit extends Vue {

}
